import { ISelectOption } from '~/core/@types/global'
import { LucideIconName } from '~/core/ui/IconWrapper'

export const OPTION_SORT_CREATE_AT_CANDIDATE: ISelectOption[] = [
  {
    value: 'desc',
    label: 'desc',
    icon: 'ArrowDown'
  },
  {
    value: 'asc',
    label: 'asc',
    icon: 'ArrowUp'
  }
]
export const DESC_SORTING = 'desc'
export const ASC_SORTING = 'asc'
export const OVERVIEW_TAB = 'overview'
export const FILE_TAB = 'files'
export const JOBS_TAB = 'jobs'
export const INTERVIEWS_TAB = 'interviews'
export const EMAIL_TAB = 'messages'
export const ACTIVITY_TAB = 'activities'
export const NOTE_TAB = 'notes'
export const TASK_TAB = 'tasks'
export const FEEDBACK_TAB = 'feedback'
export const RECOMMEND_TAB = 'recommend'

export const ListSuggestNoticeOfPeriod = [
  { value: '30' },
  { value: '45' },
  { value: '60' }
]
export const DEFAULT_CURRENCY = 'USD'
export const DEFAULT_CURRENCY_NAME = 'US Dollar'
export const LIMIT_JOB_SHOW = 5

export const FIELDS_USER_SETTING_DISPLAY: {
  [key: string]: { key: string; iconMenus: LucideIconName }
} = {
  fullName: {
    key: 'fullName',
    iconMenus: 'User'
  },
  headline: {
    key: 'headline',
    iconMenus: 'Bookmark'
  },
  email: {
    key: 'email',
    iconMenus: 'Mail'
  },
  phoneNumber: {
    key: 'phoneNumber',
    iconMenus: 'Phone'
  },
  location: {
    key: 'location',
    iconMenus: 'MapPin'
  },
  links: {
    key: 'links',
    iconMenus: 'Globe'
  },
  resume: {
    key: 'cv',
    iconMenus: 'FileText'
  },
  tag: {
    key: 'tags',
    iconMenus: 'Tag'
  },
  owner: {
    key: 'owner',
    iconMenus: 'UserCog'
  },
  jobs: {
    key: 'jobs',
    iconMenus: 'Briefcase'
  },
  stage: {
    key: 'stage',
    iconMenus: 'Database'
  },
  createdAt: {
    key: 'createdAt',
    iconMenus: 'Clock'
  },
  lastActivity: {
    key: 'lastActivity',
    iconMenus: 'Zap'
  },
  applicantDisqualified: {
    key: 'disqualifyCandidates',
    iconMenus: 'Zap'
  },
  jobArchived: {
    key: 'archivedJobs',
    iconMenus: 'Zap'
  },
  talentPool: {
    key: 'talentPools',
    iconMenus: 'FolderSearch'
  },
  summary: {
    key: 'summary',
    iconMenus: 'AlignLeft'
  },
  skills: {
    key: 'skills',
    iconMenus: 'ListChecks'
  },
  openToWork: {
    key: 'openToWork',
    iconMenus: 'FolderSearch'
  },
  languages: {
    key: 'languages',
    iconMenus: 'Languages'
  },
  nationality: {
    key: 'nationality',
    iconMenus: 'Flag'
  },
  birthday: {
    key: 'birthday',
    iconMenus: 'Cake'
  },
  willingToRelocate: {
    key: 'willingToRelocate',
    iconMenus: 'Rotate3d'
  },
  noticeToPeriodDays: {
    key: 'noticeToPeriodDays',
    iconMenus: 'CalendarClock'
  },
  currentSalary: {
    key: 'currentSalary',
    iconMenus: 'DollarSign'
  },
  expectedSalary: {
    key: 'expectedSalary',
    iconMenus: 'DollarSign'
  },
  profileLevel: {
    key: 'profileLevel',
    iconMenus: 'Zap'
  },
  totalYearsOfExp: {
    key: 'totalYearsOfExp',
    iconMenus: 'Boxes'
  }
}

export const totalYoeOptions: ISelectOption[] = Array.from(
  Array(52).keys()
).map((item) => ({
  value: String(item)
}))

export const PROFILE_CANDIDATE_TAB = {
  profile: 'profile',
  jobRelated: 'jobRelated'
}

export const CANDIDATE_STATUS_FILTER: ISelectOption[] = [
  { value: 'qualified' },
  { value: 'disqualified' },
  { value: 'new' }
]

export const CANDIDATE_CV_FILTER: ISelectOption[] = [
  { value: 'empty' },
  { value: 'not_empty' }
]

export const CANDIDATE_YES_NO: ISelectOption[] = [
  { value: 'yes' },
  { value: 'no' }
]

export const FILTER_CANDIDATE_FIELDS_VALUE = {
  job_id: 'job_id',
  applicant_statuses: 'applicant_statuses',
  stage_type_id: 'stage_type_id',
  tagged_ids: 'tagged_ids',
  company_id: 'company_id',
  owner_id: 'owner_id',
  sourced: 'sourced',
  profile_talent_pool_ids: 'profile_talent_pool_ids',
  country_state_id: 'country_state_id',
  profile_level: 'profile_level',
  created_at: 'created_at',
  updated_at: 'updated_at',
  profile_cvs_empty: 'profile_cvs_empty',
  full_name: 'full_name',
  email: 'email',
  phone_number: 'phone_number',
  links: 'links',
  summary: 'summary',
  headline: 'headline',
  birthday: 'birthday',
  open_to_work: 'open_to_work',
  skills: 'skills',
  languages: 'languages',
  willing_to_relocate: 'willing_to_relocate',
  notice_to_period_days: 'notice_to_period_days',
  total_years_of_exp: 'total_years_of_exp',
  nationality: 'nationality',
  expected_salary: 'expected_salary',
  current_salary: 'current_salary',
  operator: 'operator'
}

export const FILTER_CANDIDATE_FIELDS_CONDITION = {
  equal: 'equal',
  greaterThan: 'greater_than',
  lessThan: 'less_than',
  contains: 'contains',
  is: 'is',
  isNot: 'is_not',
  isAnyOf: 'is_any_of',
  isNoneOf: 'is_none_of',
  isEmpty: 'is_empty',
  isNotEmpty: 'is_not_empty',
  range: 'range',
  anyOfAll: 'any_of_all',
  anyOf: 'any_of',
  all: 'all',
  and: 'and',
  or: 'or'
}

export const PROFILE_VIEW_DISPLAY_DEFAULT = {
  id: undefined,
  group: undefined,
  createdById: undefined,
  name: undefined,
  state: undefined,
  stateDescription: undefined,
  profileDisplay: undefined,
  profileFilters: undefined
}

export const PROFILE_VIEW_DISPLAY_STATE = [
  {
    value: 'public'
  },
  {
    value: 'private'
  }
]

export const OPERATOR = {
  and: 'and',
  or: 'or'
}
