import { ROLE_CODE } from '~/core/constants/role'
import { IColorBadgeType } from '~/core/ui/Badge'
import { ISelectOption } from '~/core/ui/Select'

export enum JOB_STATUS_ENUM {
  publish = 'publish',
  internal = 'internal',
  archived = 'archived',
  draft = 'draft'
}

export const JOB_NOTE_TAB = 'notes'

export const DEFAULT_SELECTED_JOB_STATUS_ROLE_MAP = {
  ['default']: [
    JOB_STATUS_ENUM.draft,
    JOB_STATUS_ENUM.internal,
    JOB_STATUS_ENUM.publish
  ],
  ['defaultNoDraft']: [JOB_STATUS_ENUM.internal, JOB_STATUS_ENUM.publish],
  [ROLE_CODE.LimitedMember]: [
    JOB_STATUS_ENUM.internal,
    JOB_STATUS_ENUM.publish
  ],
  [ROLE_CODE.Admin]: [
    JOB_STATUS_ENUM.draft,
    JOB_STATUS_ENUM.internal,
    JOB_STATUS_ENUM.publish
  ],
  [ROLE_CODE.Member]: [
    JOB_STATUS_ENUM.draft,
    JOB_STATUS_ENUM.internal,
    JOB_STATUS_ENUM.publish
  ]
}

export const JOB_DOT_STATUS = (status: string) => {
  return (
    {
      [JOB_STATUS_ENUM.internal]: 'purple',
      [JOB_STATUS_ENUM.publish]: 'green',
      [JOB_STATUS_ENUM.archived]: 'red',
      [JOB_STATUS_ENUM.draft]: 'gray'
    }[status] || 'gray'
  )
}

export const JOB_COLOR_STAGE_NAME = (stageTypes: string) => {
  return (
    {
      sourced: 'blue',
      applied: 'purple',
      screening: 'red',
      'client-submission': 'orange',
      clientSubmission: 'orange',
      interview: 'yellow',
      offer: 'pastelGreen',
      hired: 'green',
      onboard: 'green',
      probation: 'green'
    }[stageTypes] || 'gray'
  )
}

export const JOB_APPLICANT_STATUS = {
  rejected: 'rejected',
  inprocessing: 'inprocessing'
}

export const JOB_STAGE_GROUP = {
  hires: 'hires',
  applicants: 'applicants',
  activeProcess: 'active_process'
}

export const LISTS_PUBLISH_JOB_BOARDS = [
  {
    code: 'google_for_jobs',
    label: 'Google for jobs',
    icon: '/img/feature/jobBoards/logoGG.svg',
    w: 80,
    h: 26,
    tooltip:
      'Have your job postings found by millions of job seekers who search on Google every day.'
  }
  // {
  //   code: 'freec',
  //   label: 'freeC',
  //   icon: '/img/feature/jobBoards/logoFreec.svg',
  //   w: 48,
  //   h: 32,
  //   tooltip:
  //     'freeC is the Smart Recruiting Platform that leverages matching technology to actively connect hundreds of thousands of employers and job seekers.'
  // }
]
export const PLACE_VIEW_PIPELINE_ENUM = {
  jobDetail: 'jobDetail',
  jobList: 'jobList'
}
export const SOURCE_REFERRAL_REWARD = [
  {
    value: 'money'
  },
  {
    value: 'orther_reward'
  }
]
export const REFERRAL_REWARD_MONEY_VALUE = 'money'
export const REFERRAL_REWARD_OTHER_VALUE = 'orther_reward'

export const JOB_STATUS_OPTION_ROLE_MAP = ({
  currentRoleCode,
  jobStatus = []
}: {
  currentRoleCode: string
  jobStatus: ISelectOption[]
}) => {
  let mappings: Array<string> = []
  if (ROLE_CODE.Admin === currentRoleCode) {
    mappings = [
      JOB_STATUS_ENUM.publish,
      JOB_STATUS_ENUM.internal,
      JOB_STATUS_ENUM.archived,
      JOB_STATUS_ENUM.draft
    ]
  }

  if (ROLE_CODE.Member === currentRoleCode) {
    mappings = [
      JOB_STATUS_ENUM.publish,
      JOB_STATUS_ENUM.internal,
      JOB_STATUS_ENUM.archived,
      JOB_STATUS_ENUM.draft
    ]
  }

  if (ROLE_CODE.LimitedMember === currentRoleCode) {
    mappings = [
      JOB_STATUS_ENUM.publish,
      JOB_STATUS_ENUM.internal,
      JOB_STATUS_ENUM.archived
    ]
  }

  return jobStatus?.filter((status) => mappings.includes(status.value))
}

export enum JOB_ACTION {
  VIEW = 0,
  DELETE = 1,
  EDIT = 2,
  CHANGE_STATUS = 3,
  DUPLICATE_JOB = 4,
  SHARE_JOB = 5
}

export const MATCHED_RANK_BADGE_COLOR: { [key: string]: IColorBadgeType } = {
  S: 'matched-rank-s',
  A: 'matched-rank-a',
  B: 'matched-rank-b',
  C: 'matched-rank-c'
}
export const SAVE_JOB_FILTER = [
  {
    value: 'all_jobs',
    supportingObj: {
      name: 'all_jobs_text'
    }
  },
  {
    value: 'saved',
    supportingObj: {
      name: 'saved_text'
    }
  },
  {
    value: 'unsaved',
    supportingObj: {
      name: 'unsaved_text'
    }
  }
]

export const SYSTEM_JOB_FIELDS = {
  remote_status: 'remote_status',
  salary: 'salary',
  employment_type: 'employment_type',
  job_level: 'job_level',
  job_talent_pool_ids: 'job_talent_pool_ids',
  tags: 'tags'
}
export const TONE_LIST = [
  {
    value: 'formal',
    supportingObj: {
      name: 'formal_tone'
    }
  },
  {
    value: 'friendly',
    supportingObj: {
      name: 'friendly_tone'
    }
  },
  {
    value: 'engaging',
    supportingObj: {
      name: 'engaging_tone'
    }
  }
]

export const TABS_KEY_MATCHING_CONDITION = {
  job: 'job',
  profile: 'profile'
}
