'use client'

import { useEffect } from 'react'

const BirdEatsBug = () => {
  useEffect(() => {
    if (typeof window === 'undefined') return
    //@ts-ignore
    const birdeatsbug = (window.birdeatsbug = window.birdeatsbug || [])
    if (birdeatsbug.initialize) return
    if (birdeatsbug.invoked) {
      console.error('Bird Eats Bug snippet included twice.')
      return
    }

    birdeatsbug.invoked = true
    birdeatsbug.methods = [
      'setOptions',
      'trigger',
      'resumeSession',
      'takeScreenshot',
      'startRecording',
      'stopRecording',
      'stopSession',
      'uploadSession',
      'deleteSession'
    ]
    //@ts-ignore
    birdeatsbug.factory = (method) =>
      //@ts-ignore
      function (...args) {
        args.unshift(method)
        birdeatsbug.push(args)
        return birdeatsbug
      }
    //@ts-ignore
    birdeatsbug.methods.forEach((method) => {
      birdeatsbug[method] = birdeatsbug.factory(method)
    })

    birdeatsbug.load = () => {
      const script = document.createElement('script')
      script.type = 'module'
      script.async = true
      script.src = 'https://sdk.birdeatsbug.com/v3/core.js'
      document.body.appendChild(script)

      const style = document.createElement('link')
      style.rel = 'stylesheet'
      style.href = 'https://sdk.birdeatsbug.com/v3/style.css'
      document.head.appendChild(style)
    }

    birdeatsbug.load()
    //@ts-ignore
    window.birdeatsbug.setOptions({
      publicAppId: 'f48237a6-32f7-46c0-b900-c1d652e7e4d8'
    })
  }, [])

  return null
}

export default BirdEatsBug
