import { ReactElement, useEffect, useMemo, useState } from 'react'

import { useRouter } from 'next/router'
import configuration from '~/configuration'

import useSubscriptionPlan from '~/components/Subscription/useSubscriptionPlan'
import { PUBLIC_APP_URL } from '~/core/constants/env'

const DEFAULT_HOSTS = [PUBLIC_APP_URL || '', 'localhost:3000']

const withClientHandleCustomDomainMiddleware = <
  T extends { pageProps: object }
>(
  Component: (props: any) => ReactElement
) => {
  const WithClientHandleCustomDomainMiddleware = <F extends T>(props: F) => {
    const router = useRouter()
    const [suspend, setSuspend] = useState(true)

    const { data } = useSubscriptionPlan()
    useEffect(() => {
      if (typeof window !== 'undefined' && router.isReady) {
        const isValidHost = DEFAULT_HOSTS.some((host) =>
          host.includes(window.location.host)
        )
        if (
          !isValidHost &&
          !router.pathname.startsWith(configuration.path.career.list)
        ) {
          router.push(configuration.path.career.list)
          // window.location.href = redirectResponse.redirect.destination
        } else {
          setSuspend(false)
        }
      }
    }, [router, data])

    return suspend ? <></> : <Component {...props} />
  }
  return WithClientHandleCustomDomainMiddleware
}
export default withClientHandleCustomDomainMiddleware
